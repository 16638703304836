
import Home from "./components/Home"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./components/Contact";
import Contact2 from "./components/Contact2";
import Features from "./components/Features";
import Navbar from "./components/navbar/Navbar";
import About from "./components/About";
import ScrollToTop from "./components/ScrollToTop";
function App() {
 

   return (

     
         <div className='lg:mx-auto max-w-5xl sm:mx-4 text-white'>
      <BrowserRouter>
     
     
        <Navbar/>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route index element={<Home />} />
          <Route path="/contact" element={<Contact2/>} />
          <Route path="/about" element={<About />} />
         
        </Routes>
     
    </BrowserRouter>
    </div>
  
   
   )
}

export default App
