import React, {useState} from 'react'
import videoImg from '../../assets/Home1.jpg'
import bgImg from '../../assets/illustration-01.svg';
import ResponsivePlayer from './ResponsivePlayer';
import { XIcon } from '@heroicons/react/solid';
import { Outlet, Link } from "react-router-dom";
import Photos from '../Photos';


const Main = () => {
	const [toggleVideoPlayer, setToggleVideoPlayer] = useState(false)

	


	const VideoPlayer = () => {
		if(toggleVideoPlayer === true) {
			return <ResponsivePlayer />
		}
	}

	return (
		<div className='mb-16  mt-32 grid grid-cols-5 gap-4  mr-60'>
			{/* <img src={bgImg} alt='/' className='absolute top-2 w-full h-40 z-[-1]'/> */}

			<div className='container flex flex-col items-center mx-auto text-center mr-8 col-span-3 mb-16'>
				<div data-aos='fade-up'>
					<h1 className='text-5xl inline-block text-black '> <h3 className='inline text-samuel'>Geredo</h3> Foreign <br/>Employment Agency</h1>
					<p className='mx-8 md:mx-16 lg:mx-12 px-4 text-xl font-medium my-3 py-4'>We are striving to be a leader in the provision of manpower services and
qualified talent across the world</p>
				</div>
				<div data-aos='fade-up' className='flex    '>
					<button 
					className='my-2 sm:my-0  bg-indigo-500 hover:bg-indigo-500/80 text-white font-bold rounded-sm py-3 mx-2 px-20 sm:px-8 transition-all'>
					  <Link to='/about'> About Us </Link>
					</button>
					<button 
					className='my-2 sm:my-0 bg-zinc-700 hover:bg-zinc-700/80 text-white font-bold rounded-sm py-3 mx-2 px-16 sm:px-8 transition-all'>
						<Link href="https://github.com/DavGrig1/landing-template-for-startups"  to='/contact' rel='noreferrer'>Contact Us</Link> 
					</button>
				</div>
			</div>

			<div data-aos='fade-up' className=' col-span-2 w-[32rem]  '>
				{toggleVideoPlayer ? 
					<VideoPlayer   />
					: 
					<img src={videoImg}    alt="/" className='cursor-pointer ' onClick={() => setToggleVideoPlayer(!toggleVideoPlayer)}/>
				}
				{toggleVideoPlayer ? 
					<XIcon onClick={() => setToggleVideoPlayer(!toggleVideoPlayer)} className='absolute cursor-pointer bottom-[-50px] left-[47%] h-8 sm:h-12 z-[1] hover:bg-indigo-500 rounded-[50%] transition-all'/>
					:
					''
				}
			</div>
		
		</div>	
  )
}

export default Main