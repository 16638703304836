import React from 'react'

const Testimonials = () => {
	
  	return (
		<div data-aos='zoom-in' className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 mb-16'>
			<div className='md:col-start-1 md:col-end-3 xl:col-auto mx-auto w-80 bg-zinc-700'>
				<h3 className='font-serif text-7xl pl-5 pt-6'>"</h3>
				<p className='pl-6 mr-16 font-medium mb-3 mt-[-20px] pb-4'>
					— I am extremely grateful to Geredo Foreign Employment Agency for their exceptional service. 
					They not only helped me find a job abroad but also ensured that the entire process was smooth and hassle-free with great</p>
				<span className='block border-b-[0.1rem] m-4 border-zinc-500'></span>
				<h1 className='text-xl pb-3 px-4'>Testimony one/ 
					<span className='text-indigo-500'>Client</span>
				</h1>
			</div>
			<div className='bg-zinc-700 w-80 mx-auto'>
				<h3 className='font-serif text-7xl pl-5 pt-6'>"</h3>
				<p className='pl-6 mr-16 font-medium mb-3 mt-[-20px] pb-4'>
				—  I had a fantastic experience with Geredo Foreign Employment Agency. Their reliability and trustworthiness set them apart in the industry. From the initial consultation to the final placement, I felt supported and confident in their abilities.
				</p>
				<span className='block border-b-[0.1rem] m-4 border-zinc-500'></span>
				<h1 className='text-xl pb-3 px-4'>Testimony two / 
					<span className='text-indigo-500'>Client</span>
				</h1>
			</div>

			<div className='bg-zinc-700 w-80 mx-auto'>
				<h3 className='font-serif text-7xl pl-5 pt-6'>"</h3>
				<p className='pl-6 mr-16 font-medium mb-3 mt-[-20px] pb-4'>
					— Geredo Foreign Employment Agency exceeded my expectations with their 
					professionalism and efficiency. The team was dedicated to understanding my skills and preferences, 
					resulting in a job placement that perfectly aligned with my aspirations. 
				</p>
				<span className='block border-b-[0.1rem] m-4 border-zinc-500'></span>
				<h1 className='text-xl pb-3 px-4'>Testimony three/ 
					<span className='text-indigo-500'>Client</span>
				</h1>
			</div>
			
			
		</div>
   )
}

export default Testimonials