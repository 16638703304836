import React, {useState} from 'react'
import logoImg from '../../assets/Geredo.jpg'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import './navbar.css';
import { Outlet, Link } from "react-router-dom";
const Navbar = () => {
	const [menuActive, setMenuActive] = useState(false)

	const toggleNav = () => setMenuActive(!menuActive)

	const ToggleBurger = () => {
		return (
			<div className={menuActive ? 'menu active' : 'menu'}>
				<div className='absolute w-full h-40 items-center justify-center flex flex-col sm:hidden '>
				<Link rel='noreferrer' to="/"
						className='mr-12 font-semibold text-black hover:text-white cursor-pointer'>Home
					</Link>
					<Link rel='noreferrer' to="/about"
						className='mr-12 font-semibold text-black hover:text-white cursor-pointer'>About
					</Link>
					<Link rel='noreferrer' to="contact"
						className='mr-12 font-semibold text-black hover:text-white cursor-pointer'>Contact
					</Link>
					<button className='bg-indigo-500 hover:bg-indigo-500/80 text-white 
					font-bold rounded-sm py-[6px] mt-8 px-20 transition-all'>Sign up</button>
				</div>
			</div>
		)
	}

	return (

		
		
		<div  className='mb-20 relative  z-10  top-0'>
			<div className='h-20 w-full flex justify-between items-center sm:bg-transparent relative '>
				<div className='mx-3'>
					<img src={logoImg} alt='/' className='cursor-pointer w-1/6'/>
				</div>

				<div onClick={toggleNav} className='flex sm:hidden mx-4 cursor-pointer relative'>
					<XIcon className={menuActive ? 'xIcon text-black  active' : 'xIcon'}/>
					<MenuIcon className={!menuActive ? 'burger text-black active' : 'burger '}/>
				</div>
				
				<div className='items-center sm:flex hidden'>
					<Link rel='noreferrer' to="/"
						className='mr-12 font-semibold text-black hover:text-indigo-500 cursor-pointer'>Home
					</Link>
					<Link rel='noreferrer' to="/about"
						className='mr-12 font-semibold text-black hover:text-indigo-500 cursor-pointer'>About
					</Link>
					<Link rel='noreferrer' to="contact"
						className='mr-12 font-semibold text-black hover:text-indigo-500 cursor-pointer'>Contact
					</Link>
					<button className='bg-indigo-500 ml-32 hover:bg-indigo-500/80 indigo-500 
					font-bold rounded-sm py-[6px] mx-3 px-6 transition-all whitespace-nowrap'> <Link to="/contact">Contact  Us</Link> </button>
				</div>
			</div>
			
			<ToggleBurger />
		</div>
	
   )
}

export default Navbar