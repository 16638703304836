import React from 'react'
import {
	StarIcon,
	DocumentTextIcon,
	GlobeAltIcon,
	MenuAlt2Icon,
	MailOpenIcon,
	PaperClipIcon } from '@heroicons/react/outline'
import RobustWorkflow from './RobustWorkflow'


const Workflow = () => {

	return (
		<div className='container mx-auto text-center'>
			<div data-aos='zoom-in'>
				<h1 className='text-3xl text-black md:text-5xl'>Our commitments are</h1>
				<p className='mx-8 md:mx-16 lg:mx-48 px-4 text-xl font-medium my-3 py-4'>We are striving to be a leader in the provision of manpower services and
qualified talent across the world</p>
			</div>

			<div data-aos='zoom-in' className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5'>
				<RobustWorkflow icon={StarIcon} title="Customer Satisfaction " text="Committed to exceeding expectations, we prioritize customer satisfaction through quality service, seamless experiences, and unwavering dedication to your happiness."/>
				<RobustWorkflow icon={DocumentTextIcon} title="Competent Staff"  text="Delivering excellence through competent staff, ensuring quality, and excelling in credible recruitment for unparalleled business success." />
				<RobustWorkflow icon={GlobeAltIcon} title="Confidentiality and Respect"  text="Our foundation rests on confidentiality and respect, safeguarding your privacy with unwavering commitment and utmost professionalism."/>
				<RobustWorkflow icon={MenuAlt2Icon} title="Social Responsibility "  text="Dedicated to fostering social responsibility across all segments, we empower communities for a collective and sustainable impact."/>
				<RobustWorkflow icon={MailOpenIcon} title="Transparent Trust"   text="Embracing trustworthiness and transparency in every interaction, ensuring integrity and reliability in all our dealings for lasting partnerships."/>
				<RobustWorkflow icon={PaperClipIcon} title="Ethical Excellence"  text="Championing trust through unwavering ethics and transparent practices in every endeavor, building lasting relationships grounded in integrity."/>
			</div>

			<span className='block border-b-[0.1rem] my-14 border-zinc-800'></span>
		</div>
  )
}

export default Workflow