import React, { useEffect } from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import Navbar from "./navbar/Navbar"
import Features from "./Features"
import Main from './main/Main'
import Workflow from './workflow/Workflow'
import Testimonials from './Testimonials'
import ContactUs from './ContactUs'
import Footer from './Footer'
import Photos from './Photos'

function Home() {
   useEffect(() => {
      Aos.init({ duration: 2500 })
   }, [])

   return (
    
      <div className='lg:mx-auto max-w-5xl sm:mx-4 text-white'>
         
         <Main />
         <Workflow />
         <Features />
         <Testimonials />
         <ContactUs />
         {/* <Photos/> */}
         <Footer />
      </div>
   )
}

export default Home
